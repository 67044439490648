const Google = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.18 8.18177C16.18 7.6145 16.1291 7.06905 16.0345 6.54541H8.5V9.63996H12.8055C12.62 10.64 12.0564 11.4872 11.2091 12.0545V14.0618H13.7945C15.3073 12.669 16.18 10.6181 16.18 8.18177Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49968 16C10.6597 16 12.4706 15.2837 13.7942 14.0618L11.2088 12.0546C10.4924 12.5346 9.57604 12.8182 8.49968 12.8182C6.41604 12.8182 4.6524 11.4109 4.02331 9.52002H1.35059V11.5927C2.66695 14.2073 5.3724 16 8.49968 16Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.02364 9.51995C3.86364 9.03995 3.77273 8.52723 3.77273 7.99995C3.77273 7.47268 3.86364 6.95995 4.02364 6.47995V4.40723H1.35091C0.809091 5.48723 0.5 6.70904 0.5 7.99995C0.5 9.29086 0.809091 10.5127 1.35091 11.5927L4.02364 9.51995Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.49968 3.18182C9.67422 3.18182 10.7288 3.58545 11.5579 4.37818L13.8524 2.08364C12.4669 0.792727 10.656 0 8.49968 0C5.3724 0 2.66695 1.79273 1.35059 4.40727L4.02331 6.48C4.6524 4.58909 6.41604 3.18182 8.49968 3.18182Z"
        fill="#EA4335"
      />
    </svg>
  );
};

export default Google;
